import { template as template_e24fe34b94024a0f8d170aa2bce02461 } from "@ember/template-compiler";
const FKLabel = template_e24fe34b94024a0f8d170aa2bce02461(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
