import { template as template_2cc7b6e894b04485acd1e7db5fe8c377 } from "@ember/template-compiler";
const SidebarSectionMessage = template_2cc7b6e894b04485acd1e7db5fe8c377(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
